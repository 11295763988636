<script>
import { BSpinner } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppIndicator',
    directives: {
        Ripple
    },
    components: {
        BSpinner,
        ToastificationContent
    },
    data() {
        return {
            response: null,
            ws: null,
            isConnected: false,
            pingInterval: null,
            spinnerColor: 'primary',
            reconnectInterval: 2000,
            reconnectTimeout: null
        };
    },
    async created() {
        await this.connectWebSocket();
    },

    methods: {
        connectWebSocket() {
            if (this.ws) {
                this.disconnectWebSocket();
            }

            if (parseInt(MERCHANT_ID) === 1) {
                this.ws = new WebSocket('ws://al.arsenal.firstit.uz/v2/wsIndicator');
            } else {
                this.ws = new WebSocket('ws://gf.arsenal.firstit.uz/v2/wsIndicator');
            }

            this.ws.onopen = () => {
                this.isConnected = true;
                if (this.reconnectTimeout) {
                    clearInterval(this.reconnectTimeout);
                    this.reconnectTimeout = null;
                }
            };

            this.ws.onmessage = (event) => {
                this.response = JSON.parse(event.data);
                if (this.response.type === 'whois') {
                    this.ws.send(JSON.stringify({ type: 'whois', resp: 'listener' }));
                } else if (this.response.type === 'status') {
                    this.spinnerColor = this.response.status;
                } else if (this.response.type === 'alert') {
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                icon: 'BellIcon',
                                title: this.response.title,
                                text: this.response.text,
                                variant: this.response.variant
                            }
                        },
                        { position: 'top-center' }
                    );
                }
            };

            this.ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            this.ws.onclose = () => {
                this.isConnected = false;
                this.scheduleReconnect();
            };
        },
        disconnectWebSocket() {
            if (this.ws) {
                this.ws.close();
                this.ws = null;
                this.isConnected = false;
                this.spinnerColor = 'primary';
            }
        },
        scheduleReconnect() {
            if (!this.isConnected && !this.reconnectTimeout) {
                this.reconnectTimeout = setInterval(() => {
                    this.connectWebSocket();
                }, this.reconnectInterval);
            }
        }
    },

    // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
    beforeDestroy() {
        this.disconnectWebSocket();
    }
};
</script>

<template>
    <div class="indicator d-flex">
        <router-link :to="{ name: 'kube-control-v2' }" class="p-1">
            <b-spinner v-if="spinnerColor === 'primary'" small :variant="spinnerColor" />
            <b-spinner v-else small :variant="spinnerColor" type="grow" />
        </router-link>
    </div>
</template>

<style lang="scss"></style>
