<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils';
import { provide, ref } from '@vue/composition-api';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';

export default {
    components: {
        VerticalNavMenuHeader,
        VerticalNavMenuLink,
        VerticalNavMenuGroup
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    computed: {
        computedLinks() {
            const newItems = [];
            this.items.map((item) => {
                if (Object.keys(item).includes('permission')) {
                    if (this.hasAccess(item.permission)) newItems.push(item);
                } else if (Object.keys(item).includes('children')) {
                    if (this.haveChildren(item).result) {
                        newItems.push(this.haveChildren(item).item);
                    }
                    // else {
                    //   if (newItems[index - 1] && newItems[index - 1].headPermission) {
                    //     newItems.splice((index-1), 1)
                    //   }
                    // }
                } else {
                    newItems.push(item);
                }
            });
            return newItems;
        }
    },
    setup() {
        provide('openGroups', ref([]));

        return {
            resolveNavItemComponent
        };
    }
};
</script>

<template>
    <ul>
        <component
            :is="resolveNavItemComponent(item)"
            v-for="(item, index) in computedLinks"
            :key="'vertical_menu_key' + index"
            :item="item"
        />
    </ul>
</template>
