<script>
import { BDropdownItem, BImg, BDropdown } from 'bootstrap-vue';
import { setLocalVar } from '@/util/localstorage-helper';
import locales from '@/constants/locales';
import Ripple from 'vue-ripple-directive';

export default {
    directives: {
        Ripple
    },
    components: {
        BDropdown,
        BDropdownItem,
        BImg
    },
    data: () => ({
        locales
    }),
    // ! Need to move this computed property to comp function once we get to Vue 3
    computed: {
        currentLocale() {
            return this.locales.find((l) => l.locale === this.$i18n.locale);
        }
    },
    methods: {
        setLang(value) {
            this.$i18n.locale = value.locale;
            setLocalVar('lang', value.locale);
            setLocalVar('locale', value.locale);
            // window.location.reload()
        }
    }
};
</script>

<template>
    <div class="position-relative mx-1 dropdown-lang">
        <b-dropdown
            id="dropdown-grouped"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="dropdown-icon-wrapper"
            left
        >
            <template #button-content>
                <b-img
                    :alt="currentLocale.locale"
                    :src="currentLocale.img"
                    height="14px"
                    width="22px"
                />
                <!--            <span class="ml-50 text-body">{{ currentLocale.name }}</span>-->
            </template>
            <b-dropdown-item
                v-for="localeObj in locales"
                :key="localeObj.locale"
                @click="setLang(localeObj)"
            >
                <b-img :alt="localeObj.locale" :src="localeObj.img" height="14px" width="22px" />
                <!--            <span class="ml-50">{{ localeObj.name }}</span>-->
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<style lang="scss">
.dropdown-language .dropdown-menu .dropdown-item {
    padding: 0.65rem 1.28rem;
}

.header-navbar .navbar-container .show .dropdown-menu {
    min-width: max-content !important;
    width: max-content !important;
}
</style>
