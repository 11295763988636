export default [
    {
        header: 'titles.subscribers'
    },
    {
        title: 'navigation.subscribers.index',
        route: 'subscribers Index',
        permission: 'subscribers'
    },
    // {
    //     title: 'navigation.subscribers.activity',
    //     route: 'subscribers Activities',
    //     permission: 'subscribers_activities'
    // },
    {
        title: 'navigation.new_subscribers_activities',
        route: 'new-subscribers-activities',
        permission: 'subscribers_activities'
    },
    {
        title: 'titles.score_history',
        route: 'score-history',
        icon: 'DatabaseIcon',
        permission: 'roles'
    },
    {
        title: 'navigation.coin_monitoring',
        route: 'coin-monitoring',
        icon: 'BarChart2Icon',
        permission: 'roles'
    },
    {
        title: 'titles.new_questionnaire',
        icon: 'CircleIcon',
        children: [
            {
                title: 'titles.feedback_polls',
                route: 'feedback-polls',
                icon: 'CircleIcon',
                permission: 'users'
            },
            {
                title: 'titles.feedback_options',
                route: 'feedback-options',
                icon: 'CircleIcon',
                permission: 'users'
            }
        ]
    },
    {
        title: 'titles.cards',
        route: 'cards',
        icon: 'BarChart2Icon',
        permission: 'users'
    },
    {
        title: 'titles.wallet_balance',
        route: 'wallet-balance',
        icon: 'DollarSignIcon',
        permission: 'users'
    }
];
