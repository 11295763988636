export default [
    // {
    //   header: 'navigation.home',
    // },
    // {
    //     title: 'navigation.home',
    //     route: 'Home',
    //     icon: 'HomeIcon',
    //     permission: 'roles'
    // },
    {
        title: 'navigation.home_v2',
        route: 'HomeV2',
        icon: 'HomeIcon',
        permission: 'roles'
    },
    // {
    //     title: 'navigation.statistics',
    //     route: 'Statistics',
    //     icon: 'ActivityIcon',
    //     permission: 'statistics_page'
    // },
    {
        title: 'titles.charges_statistics',
        route: 'new-statistics',
        icon: 'ActivityIcon',
        permission: 'statistics_page'
    },
    // {
    //     title: 'titles.registration_statistics',
    //     route: 'registration-statistics',
    //     icon: 'ActivityIcon',
    //     permission: 'statistics_page'
    // },
    {
        title: 'Chat',
        icon: 'MessageSquareIcon',
        children: [
            {
                title: 'Alchiroq',
                route: 'alchiroq-chat',
                permission: 'chat'
            },
            {
                title: 'Oltin baliq',
                route: 'oltin-baliq-chat',
                permission: 'chat'
            }
        ]
    },
    {
        title: 'navigation.statistics',
        route: 'all-statistics',
        icon: 'ActivityIcon',
        children: [
            // {
            //     title: 'navigation.bonus_statistics',
            //     route: 'bonus-statistics',
            //     permission: 'chat'
            // },
            {
                title: 'titles.appointment',
                route: 'ussd-statistics',
                icon: 'CircleIcon',
                permission: 'users'
            },
            {
                title: 'navigation.forms',
                route: 'form-statistics',
                permission: 'chat'
            },
            {
                title: 'navigation.coin_order',
                route: 'order-statistics',
                permission: 'chat'
            },
            {
                title: 'FIT-COIN',
                route: 'fitcoin-statistics',
                permission: 'chat'
            },
            {
                title: 'navigation.prizes',
                route: 'level-prize-statistics',
                permission: 'chat'
            },
            {
                title: 'titles.questionnaire_statistics',
                route: 'questionnaire-statistics',
                permission: 'chat'
            },
            {
                title: 'Flash',
                route: 'flash-statistics',
                permission: 'roles'
            },
            {
                title: 'titles.games',
                route: 'game-history',
                permission: 'roles'
            },
            {
                title: 'Roadmap',
                route: 'roadmap-statistics',
                permission: 'roles'
            },
            {
                title: 'titles.vote_statistics',
                route: 'vote-statistics',
                permission: 'roles'
            },
            {
                title: 'titles.statistics_set',
                route: 'set-statistics',
                permission: 'roles'
            }
        ]
    },
    {
        title: 'titles.match_statistics',
        route: 'match-statistics',
        icon: 'ActivityIcon',
        permission: 'match_statistics'
    }
];
