export default [
    {
        header: 'navigation.usersManagement'
    },
    {
        title: 'navigation.roles',
        route: 'Roles Index',
        icon: 'GitPullRequestIcon',
        permission: 'roles'
    },
    {
        title: 'navigation.staff',
        route: 'Staff',
        icon: 'StarIcon',
        permission: 'users'
    },
    {
        title: 'titles.dashboard_activity',
        route: 'dashboard-activity',
        icon: 'StarIcon',
        permission: 'users'
    }
];
