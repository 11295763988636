import { render, staticRenderFns } from "./AlChiroqIcon.vue?vue&type=template&id=27f02d01&scoped=true&"
import script from "./AlChiroqIcon.vue?vue&type=script&lang=js&"
export * from "./AlChiroqIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f02d01",
  null
  
)

export default component.exports