export default [
    {
        header: 'titles.segmentation',
        headPermission: 'titles.segmentation'
    },
    {
        title: 'titles.events',
        route: 'segmentation-list',
        icon: 'BarChart2Icon',
        permission: 'users'
    },
    {
        title: 'navigation.statistics',
        route: 'segmentation-statistics',
        icon: 'BarChart2Icon',
        permission: 'users'
    }
];
