export default [
    {
        header: 'Roadmap',
        headPermission: 'titles.roadmap'
    },
    {
        title: 'titles.roadmap_season',
        route: 'roadmap-season',
        icon: 'BarChart2Icon',
        permission: 'users'
    },
    {
        title: 'titles.roadmap_tasks',
        route: 'roadmap-task',
        icon: 'BarChart2Icon',
        permission: 'users'
    },
    {
        title: 'avatars',
        route: 'roadmap-avatars',
        icon: 'BarChart2Icon',
        permission: 'users'
    },
    {
        title: 'titles.roadmap_product_history',
        route: 'roadmap-product-history',
        icon: 'BarChart2Icon',
        permission: 'users'
    }
];
