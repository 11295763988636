export default [
    {
        header: 'titles.paynet'
    },
    {
        title: 'navigation.paynet',
        icon: 'TrendingUpIcon',
        permission: 'roles',
        children: [
            {
                title: 'navigation.statistics',
                route: 'paynet Statistics',
                icon: 'SlackIcon',
                permission: 'questionnaire'
            },
            {
                title: 'navigation.transactions',
                route: 'paynet Transactions',
                icon: 'SlackIcon',
                permission: 'questionnaire'
            }
        ]
    }
];
