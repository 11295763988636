export default [
    {
        header: 'navigation.new_chapter',
        headPermission: 'navigation.new_chapter'
    },
    {
        title: 'billing',
        route: 'billing',
        icon: 'BarChart2Icon',
        permission: 'users',
        children: [
            {
                title: 'packages',
                icon: 'BarChart2Icon',
                permission: 'users',
                children: [
                    {
                        title: 'settings',
                        route: 'billing-packages-settings',
                        icon: 'BarChart2Icon',
                        permission: 'users'
                    }
                ]
            },
            {
                title: 'charges',
                icon: 'BarChart2Icon',
                permission: 'users',
                children: [
                    {
                        title: 'settings',
                        route: 'billing-charges-settings',
                        icon: 'BarChart2Icon',
                        permission: 'users'
                    }
                ]
            },
            {
                title: 'billing_tariffs',
                icon: 'BarChart2Icon',
                permission: 'users',
                children: [
                    {
                        title: 'settings',
                        route: 'billing-rates-settings',
                        icon: 'BarChart2Icon',
                        permission: 'users'
                    }
                ]
            }
        ]
    },
    {
        title: 'titles.subscriptions',
        route: 'subscription',
        icon: 'BarChart2Icon',
        permission: 'users',
        children: [
            {
                title: 'titles.subscriptions',
                route: 'subscription-settings',
                icon: 'BarChart2Icon',
                permission: 'users'
            }
        ]
    },
    {
        title: 'randomizer',
        route: 'randomizer',
        icon: 'BarChart2Icon',
        permission: 'users',
        children: [
            {
                title: 'titles.vip_groups',
                route: 'infinity-groups',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.vip_products',
                route: 'infinity-products',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.bonus_categories',
                route: 'randomizer-categories',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.bonus_categories_flash',
                route: 'randomizer-categories-flash',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.bonus_categories_stock',
                route: 'randomizer-categories-stock',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'Roadmap',
                route: 'roadmap',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.sandbox',
                route: { name: 'sandbox', params: { id: 1 } },
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'Bundle',
                route: 'bonus-bundle',
                icon: 'SquareIcon',
                permission: 'winners'
            }
            // {
            //     title: 'titles.category_steps',
            //     route: 'randomizer-category-steps',
            //     icon: 'BarChart2Icon',
            //     permission: 'users'
            // }
        ]
    },
    {
        title: 'VIP',
        route: 'VIP',
        icon: 'BarChart2Icon',
        permission: 'users',
        children: [
            {
                title: 'titles.purchase_methods',
                route: 'purchases',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.purchase_history',
                route: 'purchases-history',
                icon: 'BarChart2Icon',
                permission: 'users'
            }
            // {
            //     title: 'titles.archive',
            //     route: 'vip-archive',
            //     icon: 'BarChart2Icon',
            //     permission: 'users'
            // }
        ]
    }
];
