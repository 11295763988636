export default [
    {
        header: 'ads',
        headPermission: 'navigation.ads'
    },
    {
        title: 'ads',
        icon: 'TagIcon',
        children: [
            {
                title: 'company',
                route: 'ads-company',
                icon: 'BriefcaseIcon',
                color: 'success',
                permission: 'ad_companies'
            },
            {
                title: 'telegram-channels',
                route: 'telegram-channels',
                icon: 'BriefcaseIcon',
                color: 'success',
                permission: 'telegram_channels'
            }
            // {
            //     title: 'navigation.advertisement',
            //     route: 'ads-advertisement',
            //     icon: 'SlackIcon',
            //     // permission: 'merchants'
            // },
        ]
    }
];
