export default [
    {
        header: 'titles.stock',
        headPermission: 'titles.stock'
    },
    {
        title: 'titles.stock_templates',
        route: 'stock-templates',
        icon: 'BarChart2Icon',
        permission: 'news'
    },
    {
        title: 'titles.stock_category',
        route: 'stock-categories',
        icon: 'BarChart2Icon',
        permission: 'news'
    },
    {
        title: 'titles.stock_history',
        route: 'stock-history',
        icon: 'BarChart2Icon',
        permission: 'news'
    },
    {
        title: 'titles.special_offers',
        route: 'special-offers',
        icon: 'BarChart2Icon',
        permission: 'news'
    }
];
