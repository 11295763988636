export default [
    {
        header: 'USSD',
        headPermission: 'titles.roadmap'
    },
    {
        title: 'USSD',
        icon: 'CircleIcon',
        children: [
            {
                title: 'titles.ussd_menu',
                route: 'ussd-menu',
                icon: 'CircleIcon',
                permission: 'users'
            },
            {
                title: 'titles.ussd_texts',
                route: 'ussd-texts',
                icon: 'CircleIcon',
                permission: 'users'
            },
            {
                title: 'titles.total_activity',
                route: 'ussd-total-activity',
                icon: 'CircleIcon',
                permission: 'users'
            },
            {
                title: 'titles.new_user_statistics',
                route: 'ussd-new-user-statistics',
                icon: 'CircleIcon',
                permission: 'users'
            }
        ]
    }
];
