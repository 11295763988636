export default [
    // {
    //     title: 'navigation.levels',
    //     icon: 'TrendingUpIcon',
    //     children: [
    //         {
    //             title: 'navigation.levels',
    //             route: 'Levels',
    //             icon: 'TerminalIcon',
    //             permission: 'levels'
    //         },
    //         {
    //             title: 'navigation.levelsGifts',
    //             route: 'Levels Gifts',
    //             icon: 'TriangleIcon',
    //             permission: 'levels_gifts'
    //         },
    //         {
    //             title: 'navigation.levelsScore',
    //             route: 'Levels Score',
    //             icon: 'SquareIcon',
    //             permission: 'levels_score'
    //         },
    //         {
    //             title: 'navigation.levelsWinners',
    //             route: 'Levels Winners',
    //             icon: 'SquareIcon',
    //             permission: 'winners'
    //         }
    //     ]
    // }
];
