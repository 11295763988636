export default [
    {
        title: 'navigation.new_level',
        icon: 'TrendingUpIcon',
        children: [
            {
                title: 'navigation.levels',
                route: 'new-level',
                icon: 'TerminalIcon',
                permission: 'levels'
            },
            {
                title: 'navigation.levelsGifts',
                route: 'new-prize',
                icon: 'TriangleIcon',
                permission: 'levels_gifts'
            },
            {
                title: 'navigation.level_actions',
                route: 'new-actions',
                icon: 'SquareIcon',
                permission: 'levels_score'
            },
            {
                title: 'navigation.level_statistics',
                route: 'new-level-statistics-balance',
                icon: 'SquareIcon',
                permission: 'winners'
            }
        ]
    }
];
