export default [
    {
        header: 'navigation.dealers',
        headPermission: 'navigation.dealers'
    },
    {
        title: 'navigation.dealers',
        icon: 'CircleIcon',
        children: [
            {
                title: 'titles.dealers_statistics',
                route: 'dealers',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.dealer_accounts',
                route: 'dealers-accounts',
                icon: 'CircleIcon',
                permission: 'users'
            },
            {
                title: 'titles.dealer_services',
                route: 'dealers-service',
                icon: 'CircleIcon',
                permission: 'users'
            },
            {
                title: 'titles.dealer_constants',
                route: 'dealers-constants' || 'dealers-constants-overall-statistics',
                // route: 'dealers-constants',
                icon: 'CircleIcon',
                permission: 'users'
            }
        ]
    }
];
