<script>
import { setLocalVar } from '@/util/localstorage-helper';
import { MERCHANT_ID, triggerMerchant } from '@/constants/names';
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import alch from '@/assets/images/icons/al-chiroq-logo.png';
import gfsh from '@/assets/images/icons/golden-fish-icon.png';

export default {
    directives: {
        Ripple
    },
    components: {
        BDropdown,
        BDropdownItem
    },
    data() {
        const merchantOptions = [
            {
                id: 1,
                name: 'Al-chiroq'
            },
            {
                id: 2,
                name: 'Oltin Baliq'
            }
        ];

        const vmCMerchant = parseInt(MERCHANT_ID);

        return {
            vmCMerchant,
            merchantOptions
        };
    },
    watch: {
        vmCMerchant(vm) {
            this.setMerchant(vm);
        }
    },
    methods: {
        setMerchant(merchant) {
            const redirectRouteNames = [
                'subscriber-task-progress',
                'subscriber-coin-orders',
                'subscriber-coin-monitoring',
                'subscribers Single Wins',
                'subscribers Single Activate Packet As Subscriber',
                'subscribers Single Manual Packets',
                'subscribers Single Transactions',
                'subscribers Single Gifts',
                'subscribers Single Activities',
                'subscribers Single',
                'subscriber-new-logger',
                'subscriber-streak'
            ];

            const redirectSegmentationRouteNames = [
                'segmentation-detail-rate-plan',
                'segmentation-detail-zone',
                'segmentation-detail-sale-point',
                'segmentation-user-info'
            ];

            if (redirectRouteNames.includes(this.$route.name)) {
                this.$router.push({ name: 'subscribers Index' });
            }

            if (redirectSegmentationRouteNames.includes(this.$route.name)) {
                this.$router.push({ name: 'segmentation-list' });
            }

            triggerMerchant(merchant);
            setLocalVar('merchant', merchant);
            window.location.reload();
        }
    },
    computed: {
        imageSource() {
            return this.vmCMerchant === 1 ? alch : gfsh;
        },
        currentMerchant() {
            if (this.merchantOptions && MERCHANT_ID) {
                return this.merchantOptions.find(
                    (merchant) => parseInt(merchant['id']) === parseInt(MERCHANT_ID)
                );
            }
            return {
                id: 2,
                name: 'Oltin Baliq'
            };
        }
    }
};
</script>

<template>
    <div class="d-flex position-relative" style="column-gap: 1rem; margin-right: 1rem">
        <!--        <b-form-radio v-model="vmCMerchant" name="merchant-radio" :value="2">-->
        <!--            Oltin baliq-->
        <!--        </b-form-radio>-->
        <!--        <b-form-radio v-model="vmCMerchant" name="merchant-radio" :value="1">-->
        <!--            Alchiroq-->
        <!--        </b-form-radio>-->
        <b-dropdown
            id="dropdown-grouped"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            right
            class="dropdown-icon-wrapper asd"
        >
            <template #button-content>
                <img style="width: 30px; height: 30px" :src="imageSource" alt="" />
            </template>
            <b-dropdown-item @click="setMerchant(1)">
                <img
                    style="width: 30px; height: 30px"
                    src="@/assets/images/icons/al-chiroq-logo.png"
                    alt=""
                />
            </b-dropdown-item>
            <b-dropdown-item @click="setMerchant(2)">
                <img
                    style="width: 30px; height: 30px"
                    src="@/assets/images/icons/golden-fish-icon.png"
                    alt=""
                />
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<style lang="scss">
.dropdown-merchant .dropdown-menu .dropdown-item {
    padding: 0.65rem 1.28rem;
}

.dropdown-toggle {
    padding: 0 !important;
    background-color: transparent !important;
    border: none !important;
}
</style>
