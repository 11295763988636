export default [
    {
        header: 'titles.server_settings',
        headPermission: 'titles.server_settings'
    },
    {
        title: 'titles.server_settings_v2',
        icon: 'BarChart2Icon',
        permission: 'users',
        children: [
            {
                title: 'titles.control',
                route: 'kube-control-v2',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.queues',
                route: 'kube-queue-v2',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.kube-servers',
                route: 'kube-servers-v2',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.cluster',
                route: 'kube-cluster-v2',
                icon: 'BarChart2Icon',
                permission: 'users'
            }
        ]
    },
    {
        title: 'titles.server_settings',
        icon: 'BarChart2Icon',
        permission: 'users',
        children: [
            {
                title: 'titles.kube-servers',
                route: 'kube-servers',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.control',
                route: 'kube-control',
                icon: 'BarChart2Icon',
                permission: 'users'
            },
            {
                title: 'titles.queue_types',
                route: 'kube-groups',
                icon: 'BarChart2Icon',
                permission: 'users'
            }
        ]
    },
    {
        title: 'titles.record_logs',
        route: 'record-logs',
        icon: 'MonitorIcon',
        permission: 'roles'
    }
];
