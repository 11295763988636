<script>
import navMenuItems from '@/navigation/vertical';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { BLink } from 'bootstrap-vue';
import { computed, provide, ref } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import { $themeConfig } from '@themeConfig';
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
import useVerticalNavMenu from './useVerticalNavMenu';
import OltinBaliqIcon from '@/components/icons/OltinBaliqIcon.vue';
import AlChiroqIcon from '@/components/icons/AlChiroqIcon.vue';

export default {
    components: {
        AlChiroqIcon,
        OltinBaliqIcon,
        VuePerfectScrollbar,
        VerticalNavMenuItems,
        BLink
    },
    props: {
        isVerticalMenuActive: {
            type: Boolean,
            required: true
        },
        toggleVerticalMenuActive: {
            type: Function,
            required: true
        }
    },
    async beforeCreate() {
        // const { data: systemInfo } = await api.system.fetchSystemInfo()
        // this.appLogoImage = systemInfo.logo ?? this.appLogoImage
        // this.appName = systemInfo['project_name'] ?? this.appName
    },
    setup(props) {
        const {
            isMouseHovered,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            updateMouseHovered
        } = useVerticalNavMenu(props);

        const { skin } = useAppConfig();

        // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
        const shallShadowBottom = ref(false);

        provide('isMouseHovered', isMouseHovered);

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false
        };

        const collapseTogglerIconFeather = computed(() =>
            collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'
        );

        // App Name
        let { appName, appLogoImage } = $themeConfig.app;

        let appId = localStorage.getItem('merchant');

        return {
            navMenuItems,
            perfectScrollbarSettings,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            isMouseHovered,
            updateMouseHovered,
            collapseTogglerIconFeather,

            // Shadow Bottom
            shallShadowBottom,

            // Skin
            skin,

            // App Name
            appName,
            appLogoImage,
            appId
        };
    }
};
</script>

<template>
    <div
        class="main-menu menu-fixed menu-accordion menu-shadow"
        :class="[
            { expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
            skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
        ]"
        @mouseenter="updateMouseHovered(true)"
        @mouseleave="updateMouseHovered(false)"
    >
        <!-- main menu header-->
        <div class="navbar-header expanded">
            <slot
                name="header"
                :toggleVerticalMenuActive="toggleVerticalMenuActive"
                :toggleCollapsed="toggleCollapsed"
                :collapseTogglerIcon="collapseTogglerIcon"
            >
                <ul class="nav navbar-nav flex-row">
                    <!-- Logo & Text -->
                    <li class="nav-item mr-auto">
                        <b-link class="navbar-brand mt-1" to="/">
                            <span class="brand-logo">
                                <!--                                <b-img :src="appLogoImage" />-->

                                <!--                <img src="src/assets/images/logo/bm-mobiuz.png" alt="">-->
                            </span>
                            <!--                            <h2 class="brand-text pl-0">-->
                            <!--                                <span v-if="appId === '1'">Al-Chiroq</span>-->
                            <!--                                <span v-else>Oltin Baliq</span>-->
                            <AlChiroqIcon v-if="appId === '1'" />
                            <OltinBaliqIcon v-else />
                            <!--                               {{ appName }}-->
                            <!--                            </h2>-->
                        </b-link>
                    </li>

                    <!-- Toggler Button -->
                    <li class="nav-item nav-toggle">
                        <b-link class="nav-link modern-nav-toggle">
                            <feather-icon
                                icon="XIcon"
                                size="20"
                                class="d-block d-xl-none"
                                @click="toggleVerticalMenuActive"
                            />
                            <feather-icon
                                :icon="collapseTogglerIconFeather"
                                size="20"
                                class="d-none d-xl-block collapse-toggle-icon"
                                @click="toggleCollapsed"
                            />
                        </b-link>
                    </li>
                </ul>
            </slot>
        </div>
        <!-- / main menu header-->

        <!-- Shadow -->
        <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

        <!-- main menu content-->
        <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="main-menu-content scroll-area"
            tagname="ul"
            @ps-scroll-y="
                (evt) => {
                    shallShadowBottom = evt.srcElement.scrollTop > 0;
                }
            "
        >
            <vertical-nav-menu-items :items="navMenuItems" class="navigation navigation-main" />
        </vue-perfect-scrollbar>
        <!-- /main menu content-->
    </div>
</template>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';

.navigation-main {
    padding-bottom: 40px !important;
}
</style>
