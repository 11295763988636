export default [
    {
        header: 'navigation.olds'
    },
    {
        title: 'navigation.olds',
        icon: 'BoxIcon',
        children: [
            {
                title: 'navigation.home',
                route: 'Home',
                icon: 'HomeIcon',
                permission: 'roles'
            },
            {
                title: 'navigation.levels',
                icon: 'TrendingUpIcon',
                children: [
                    {
                        title: 'navigation.levels',
                        route: 'Levels',
                        icon: 'TerminalIcon',
                        permission: 'levels'
                    },
                    {
                        title: 'navigation.levelsGifts',
                        route: 'Levels Gifts',
                        icon: 'TriangleIcon',
                        permission: 'levels_gifts'
                    },
                    {
                        title: 'navigation.levelsScore',
                        route: 'Levels Score',
                        icon: 'SquareIcon',
                        permission: 'levels_score'
                    },
                    {
                        title: 'navigation.levelsWinners',
                        route: 'Levels Winners',
                        icon: 'SquareIcon',
                        permission: 'winners'
                    }
                ]
            },
            {
                title: 'navigation.subscribers.activity',
                route: 'subscribers Activities',
                permission: 'subscribers_activities'
            },
            {
                title: 'navigation.news',
                route: 'News-V1',
                icon: 'SlackIcon',
                permission: 'news'
            },
            {
                title: 'navigation.statistics',
                route: 'Statistics',
                icon: 'ActivityIcon',
                permission: 'statistics_page'
            }
        ]
    }
];
