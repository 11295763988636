export default [
    {
        header: 'navigation.tournament'
    },
    {
        title: 'navigation.tournament',
        icon: 'BoxIcon',
        children: [
            {
                title: 'tournament.statistics',
                route: 'tournament-statistics',
                icon: 'TrendingUpIcon',
                permission: 'tournament_policy'
            },
            {
                title: 'tournament.tournaments_title',
                route: 'tournament-tournaments',
                icon: 'AwardIcon',
                permission: 'tournaments'
            },
            {
                title: 'tournament.groups',
                route: 'tournament-groups',
                icon: 'UsersIcon',
                permission: 'tournament-groups'
            },
            {
                title: 'tournament.simple_tasks_title',
                route: 'tasks',
                icon: 'FileTextIcon',
                permission: 'tournament_tasks'
            },
            {
                title: 'tournament.winners',
                route: 'tournament-list',
                icon: 'UsersIcon',
                permission: 'tournament_tasks'
            },
            // {
            //     title: 'tournament.tasks_title',
            //     route: 'tournament-tasks',
            //     icon: 'SlackIcon',
            //     permission: 'news'
            // },
            {
                title: 'tournament.banners_title',
                route: 'tournament-banners',
                icon: 'ImageIcon',
                permission: 'tournament_banners'
            },
            {
                title: 'tournament.products_title',
                route: 'tournament-product',
                icon: 'SlackIcon',
                permission: 'tournament_products'
            },
            {
                title: 'navigation.policies',
                route: 'tournament-policy',
                icon: 'ShieldIcon',
                permission: 'tournament_policy'
            }
        ]
    }
];
