export default [
    {
        header: 'navigation.market',
        headPermission: 'navigation.coin'
    },
    {
        title: 'navigation.market',
        icon: 'DollarSignIcon',
        permission: 'roles',
        children: [
            {
                title: 'product_analytics',
                route: 'coin-analytics',
                icon: 'BarChart2Icon'
            },
            {
                title: 'navigation.coin_order',
                route: 'coin-order',
                icon: 'ShoppingBagIcon'
            },
            {
                title: 'navigation.coin_product',
                route: 'coin-product',
                icon: 'SlackIcon'
            },
            {
                title: 'product_category',
                route: 'product-category',
                icon: 'ListIcon',
                permission: 'product_categories'
            },
            // {
            //     title: 'product_analytics',
            //     route: 'coin-analytics',
            //     icon: 'ListIcon',
            //     permission: 'coin_analytics'
            // },
            {
                title: 'navigation.warehouse',
                route: 'warehouse',
                icon: 'BoxIcon'
            },
            {
                title: 'navigation.coin_merchant',
                route: 'coin-merchant',
                icon: 'SlackIcon',
                permission: 'merchants'
            },
            {
                title: 'navigation.coin_award',
                route: 'coin-awards',
                icon: 'SlackIcon',
                permission: 'coin_award'
            },
            {
                title: 'navigation.coin_award_type',
                route: 'coin-award-types',
                icon: 'SlackIcon',
                permission: 'coin_award'
            },
            {
                title: 'navigation.coin_measurement',
                route: 'coin-measurement',
                icon: 'SlackIcon',
                permission: 'coin_measurement'
            },
            {
                title: 'navigation.policies',
                route: 'market-policy',
                icon: 'SlackIcon',
                permission: 'market-policy'
            }
        ]
    }
];
