export default [
    {
        title: 'navigation.informations',
        icon: 'HelpCircleIcon',
        children: [
            {
                title: 'navigation.policies',
                route: 'Policies',
                icon: 'ShieldIcon',
                permission: 'policies'
            },
            {
                title: 'navigation.new_policies',
                route: 'new_policies',
                icon: 'ShieldIcon',
                permission: 'policies'
            },
            {
                title: 'navigation.prizes',
                route: 'Prizes',
                icon: 'ZapIcon',
                permission: 'prizes'
            },
            {
                title: 'titles.on_board',
                route: 'Guides',
                icon: 'OctagonIcon',
                permission: 'guides'
            },
            {
                title: 'navigation.socials',
                route: 'Socials',
                icon: 'LinkIcon',
                permission: 'socials'
            },
            {
                title: 'titles.banners',
                route: 'banners',
                icon: 'MonitorIcon',
                permission: 'roles'
            }
        ]
    }
];
