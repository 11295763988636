export default [
    {
        header: 'navigation.others'
    },
    // {
    //     title: 'navigation.news',
    //     route: 'News-V1',
    //     icon: 'SlackIcon',
    //     permission: 'news'
    // },
    {
        title: 'navigation.others',
        icon: 'BoxIcon',
        children: [
            {
                title: 'titles.news_v2',
                route: 'News',
                icon: 'SlackIcon',
                permission: 'news'
            },
            {
                title: 'navigation.offers',
                route: 'Offers',
                icon: 'SlackIcon',
                permission: 'offers'
            },
            {
                title: 'navigation.gifts',
                route: 'Gifts',
                icon: 'GiftIcon',
                permission: 'gifts'
            },
            {
                title: 'navigation.categories',
                route: 'Categories',
                icon: 'GridIcon',
                permission: 'categories'
            },
            {
                title: 'avatars',
                route: 'avatars',
                icon: 'TrelloIcon',
                permission: 'roles'
            },
            {
                title: 'regions',
                route: 'regions',
                icon: 'GlobeIcon',
                permission: 'roles'
            },
            {
                title: 'cities',
                route: 'cities',
                icon: 'MapPinIcon',
                permission: 'roles'
            },
            {
                title: 'notifications',
                route: 'notifications',
                icon: 'BellIcon',
                permission: 'roles'
            },
            {
                title: 'server_keys',
                route: 'server-keys',
                icon: 'ServerIcon',
                permission: 'roles'
            },
            {
                title: 'languages',
                route: 'languages',
                icon: 'TwitchIcon',
                permission: 'roles'
            },
            {
                title: 'translations',
                route: 'translations',
                icon: 'TwitchIcon',
                permission: 'roles'
            },
            {
                title: 'titles.games',
                route: 'games',
                icon: 'PlayCircleIcon',
                permission: 'roles'
            },
            // {
            //     title: 'titles.game_history',
            //     route: 'game-history',
            //     icon: 'PlayCircleIcon',
            //     permission: 'roles'
            // },
            // {
            //     title: 'titles.flashes',
            //     route: 'flash',
            //     icon: 'CloudLightningIcon',
            //     permission: 'roles'
            // },
            // {
            //     title: 'titles.flash_history',
            //     route: 'flash-history',
            //     icon: 'CloudLightningIcon',
            //     permission: 'roles'
            // },
            // {
            //     title: 'titles.flash_statistics',
            //     route: 'flash-statistics',
            //     icon: 'CloudLightningIcon',
            //     permission: 'roles'
            // },
            // {
            //     title: 'titles.collector',
            //     route: 'collector',
            //     icon: 'MonitorIcon',
            //     permission: 'roles'
            // },
            // {
            //     title: 'titles.collector_activity',
            //     route: 'collector-activity',
            //     icon: 'MonitorIcon',
            //     permission: 'roles'
            // },
            // {
            //     title: 'titles.kube-servers',
            //     route: 'servers',
            //     icon: 'MonitorIcon',
            //     permission: 'roles'
            // },
            // {
            //     title: 'titles.control',
            //     route: 'control',
            //     icon: 'MonitorIcon',
            //     permission: 'roles'
            // },
            // {
            //     title: 'titles.bonus_statistics',
            //     route: 'bonus-statistics',
            //     icon: 'MonitorIcon',
            //     permission: 'roles'
            // },
            {
                title: 'titles.subscribers_service',
                route: 'subscribers-service',
                icon: 'UsersIcon',
                permission: 'roles'
            },
            {
                title: 'titles.referral_prize',
                route: 'referral-prize',
                icon: 'UsersIcon',
                permission: 'roles'
            },
            {
                title: 'titles.alert_modal',
                route: 'alert-modal',
                icon: 'UsersIcon',
                permission: 'roles'
            }
            // {
            //     title: 'faq',
            //     route: 'faq',
            //     icon: 'FileTextIcon'
            // },
        ]
    }
];
