import { MERCHANT_ID } from '@/constants/names';

export default [
    {
        header: 'titles.administration',
        headPermission: 'titles.administration'
    },
    {
        title: 'titles.administration',
        route: parseInt(MERCHANT_ID) === 1 ? 'ucell-status' : 'uzmobile-status',
        icon: 'BarChart2Icon',
        permission: 'users'
    }
];
