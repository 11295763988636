<script>
import { BAvatar, BDropdownItem, BNavItemDropdown } from 'bootstrap-vue';
import { initialAbility } from '@/libs/acl/config';
// import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter';
import { getLocalVar, removeLocalVar } from '@/util/localstorage-helper';

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BAvatar
    },
    data() {
        return {
            userData: JSON.parse(getLocalVar('userData')),
            avatarText
        };
    },
    methods: {
        logout() {
            // Remove userData from localStorage
            removeLocalVar('userData');
            removeLocalVar('accessToken');
            // Reset ability
            this.$ability.update(initialAbility);

            // Redirect to login page
            this.$router.push({ name: 'auth Login' });
        }
    }
};
</script>

<template>
    <div class="position-relative">
        <b-nav-item-dropdown
            right
            toggle-class="d-flex align-items-center dropdown-user-link"
            class="dropdown-user"
        >
            <template #button-content>
                <div class="d-sm-flex d-none user-nav">
                    <p class="user-name font-weight-bolder mb-0">
                        {{ userData.name }}
                    </p>
                    <!--        <span class="user-status">{{ $t('user_dropdown.role') }}</span>-->
                </div>
                <!--      <b-avatar-->
                <!--        size="40"-->
                <!--        :src="userData.avatar"-->
                <!--        variant="light-primary"-->
                <!--        badge-->
                <!--        class="badge-minimal"-->
                <!--        badge-variant="success"-->
                <!--      >-->
                <!--        <feather-icon-->
                <!--          v-if="!userData.fullName"-->
                <!--          icon="UserIcon"-->
                <!--          size="22"-->
                <!--        />-->
                <!--      </b-avatar>-->
            </template>

            <!--    <b-dropdown-item-->
            <!--      :to="{ name: 'settings' }"-->
            <!--      link-class="d-flex align-items-center"-->
            <!--    >-->
            <!--      <feather-icon-->
            <!--        size="16"-->
            <!--        icon="SettingsIcon"-->
            <!--        class="mr-50"-->
            <!--      />-->
            <!--      <span>{{ $t('user_dropdown.settings') }}</span>-->
            <!--    </b-dropdown-item>-->
            <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                <span>{{ $t('exit') }}</span>
            </b-dropdown-item>
        </b-nav-item-dropdown>
    </div>
</template>

<style lang="scss">
.vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .dropdown-menu {
    top: 100% !important;
    right: 0 !important;
    left: inherit !important;
}
.dropdown-user .dropdown-menu-right .dropdown-item {
    padding: 0.65rem 1.28rem;
}
</style>
