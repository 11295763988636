<script>
import { BLink, BNavbarNav, VBModal } from 'bootstrap-vue';
import Locale from './components/Locale.vue';
// import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue';
// import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue';
import HeaderLeftItems from './components/HeaderLeftItems.vue';
import MerchantToggler from '@core/layouts/components/app-navbar/components/MerchantToggler.vue';
import AppIndicator from '@core/layouts/components/app-navbar/components/AppIndicator.vue';
import Ripple from 'vue-ripple-directive';

export default {
    components: {
        MerchantToggler,
        HeaderLeftItems,
        BLink,

        // Navbar Components
        BNavbarNav,
        Locale,
        // SearchBar,
        DarkToggler,
        // CartDropdown,
        // NotificationDropdown,
        UserDropdown,
        AppIndicator
    },
    directives: {
        'b-modal': VBModal,
        Ripple
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {}
        }
    },
    // created() {
    //   localStorage.getItem('accessToken') && this.CheckLogin()
    // },
    methods: {
        // login() {
        //   const user = {
        //     username: 'admin',
        //     password: 'vu8eaajiaw'
        //   }
        //   console.log(user, 'user')
        //   api.auth.signIn(user)
        //     .then((response) => {
        //       const {
        //         token: access_token
        //       } = response.data
        //       // setLocalVar('auth__refresh__token', refresh_token)
        //       const { data } = response.data
        //       setLocalVar('accessToken', access_token)
        //       setLocalVar('userData', JSON.stringify(data))
        //       this.$router.push({ name: 'Home' })
        //     })
        //     .catch((error) => {
        //       console.log(error)
        //       if (!error.response) {
        //         this.dismissCountDown = 4
        //
        //       } else {
        //         const status = error.response.status
        //         const message = error.response.data.message
        //         this.errorMessage = error.response.data.errorMessage
        //         this.errorMessage = 'Неверный логин или пароль!'
        //         this.showDismissibleAlert = true
        //
        //         /* CLIENT AND SERVER ERROR */
        //         if (status && status >= 400 && status <= 511) {
        //           this.dismissCountDown = 4
        //         }
        //       }
        //     })
        // }
    }
};
</script>

<template>
    <div class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link class="nav-link" @click="toggleVerticalMenuActive">
                    <feather-icon icon="MenuIcon" size="21" />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <!--        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">-->
        <div class="bookmark-wrapper align-items-center flex-grow-1">
            <!-- Bookmarks Container -->
            <!--      <bookmarks />-->
            <AppIndicator />
            <!--            <header-left-items />-->
        </div>

        <b-navbar-nav class="nav align-items-center ml-auto">
            <merchant-toggler />
            <locale />
            <dark-Toggler class="d-block" />
            <!--      <search-bar />-->
            <!--      <cart-dropdown />-->
            <!--      <notification-dropdown />-->
            <user-dropdown />
        </b-navbar-nav>
    </div>
</template>
