export default [
    {
        header: 'titles.questionnaire'
    },
    {
        title: 'navigation.questionnaire',
        icon: 'TrendingUpIcon',
        children: [
            {
                title: 'navigation.organizations',
                route: 'organizations',
                icon: 'SlackIcon',
                permission: 'vote_organizations'
            },
            {
                title: 'navigation.questionnaireSettings',
                route: 'vote-inputs',
                icon: 'TriangleIcon',
                permission: 'vote_inputs'
            }
        ]
    }
];
