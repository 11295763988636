export default [
    {
        header: 'notifications',
        headPermission: 'titles.notification_templates'
    },
    {
        title: 'titles.notification_manual',
        route: 'notification-manual-one',
        icon: 'BarChart2Icon',
        permission: 'news'
    },
    {
        title: 'titles.notification_templates',
        route: 'notification-templates',
        icon: 'BarChart2Icon',
        permission: 'news'
    },
    {
        title: 'titles.notification_portion',
        route: 'notification-portion',
        icon: 'BarChart2Icon',
        permission: 'news'
    },
    {
        title: 'titles.notification_history',
        route: 'notification-history',
        icon: 'BarChart2Icon',
        permission: 'news'
    },
    {
        title: 'titles.notification_statistics',
        route: 'notification-statistics',
        icon: 'BarChart2Icon',
        permission: 'news'
    },
    {
        title: 'titles.sms',
        route: 'sms',
        icon: 'MailIcon',
        permission: 'roles'
    }
];
