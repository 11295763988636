export default [
    {
        header: 'titles.scenario',
        headPermission: 'titles.scenario_prize'
    },
    {
        title: 'titles.scenario',
        route: 'scenario',
        icon: 'BarChart2Icon',
        permission: 'users'
    },
    {
        title: 'titles.scenario_prize',
        route: 'scenario-prize',
        icon: 'BarChart2Icon',
        permission: 'users'
    },
    {
        title: 'titles.scenario_notification',
        route: 'scenario-notification',
        icon: 'BarChart2Icon',
        permission: 'users'
    },
    {
        title: 'titles.scenario_level',
        route: 'scenario-level',
        icon: 'BarChart2Icon',
        permission: 'users'
    }
];
